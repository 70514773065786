<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Intitulé poste" />
        </div>

        <!-- la barre de progression -->
        <v-row justify="center">
          <v-col xl="6" lg="8" md="8" sm="10" xs="12">
            <!-- la progess bar à afficher lors du chargement des données -->
            <v-progress-linear
              indeterminate
              :active="loading"
            ></v-progress-linear>
          </v-col>
        </v-row>

        <!-- le détail du service -->
        <DetailViewComponent 
          title="Détail de l'intitulé poste" 
          :fields="fields" 
        />
      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar>

  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewComponent from "@/components/ui/DetailViewComponent.vue";

import { RomeHightDomainsService } from "@/service/dictionary/rome_hight_domains_service.js";
import { RomeJobsService } from "@/service/dictionary/rome_jobs_service.js";
import { InternalJobsService } from "@/service/dictionary/internal_jobs_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import {
  addFieldWithOneValue,
  addFieldWithSeveralValues,
} from "@/components/ui/detail_view_component_tool.js";

export default {
  name: "DetailJobsTitle",
  components: { Workflow, TitleAndReturnComponent, DetailViewComponent },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin,],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service d'accès aux apis. */
      serviceInternalJobs: null,
      serviceOfficialJobs: null,
      serviceRomeHightDomains: null,

      /**l'identifiant de la grandeur à visualiser. */
      entityId: null,
      entityInternal: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /**les champs à afficher en détail */
      fields: [],
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        this.fields = [];

        let entity = null;
        let alias = null;

        // En fonction de la provenance du métier interne ou officiel
        if (this.entityInternal) {
          entity = await this.serviceInternalJobs.getById(this.entityId);
        } else {
          entity = await this.serviceOfficialJobs.getById(this.entityId);
          alias = entity.alias.split(",");
          // Tri des alias pour une présentation en ordre alphabétique.
          alias.sort(function (a, b) {
            return a.localeCompare(b);
          })
        }

        // Récupération du grand domaine rome
        let romeHightDomain = await this.serviceRomeHightDomains.getById(entity.romeHightDomainCode);

        // Affichage des champs selon métier interne ou officiel
        if (this.entityInternal) {
          addFieldWithOneValue(this.fields, "Alias ROME", entity.alias || '-', true);
          addFieldWithOneValue(this.fields, "Grand domaine ROME", romeHightDomain.label || '-', true);
          addFieldWithOneValue(this.fields, "Définition (mini-fiche de poste)", entity.definition || '-', true);
          addFieldWithOneValue(this.fields, "Accès emploi métier", entity.acces || '-', true);
          addFieldWithOneValue(this.fields, "Compétences de base", entity.basicSkills || '-', false);
        } else {
          addFieldWithOneValue(this.fields, "Métier ROME", entity.label || '-', true);
          addFieldWithOneValue(this.fields, "Code ROME", entity.code || '-', true);
          addFieldWithOneValue(this.fields, "Grand domaine ROME", romeHightDomain.label || '-', true);
          addFieldWithOneValue(this.fields, "Définition (mini-fiche de poste)", entity.definition || '-', true);
          addFieldWithOneValue(this.fields, "Accès emploi métier", entity.acces || '-', true);
          addFieldWithOneValue(this.fields, "Compétences de base", entity.basicSkills || '-', true);
          addFieldWithSeveralValues(this.fields, "Alias ROME", alias || '-', false);
        }
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
  mounted() {
    
    // Instanciation des services
    this.serviceRomeHightDomains = new RomeHightDomainsService(this.$api.getRomeHightDomainsApi());
    this.serviceInternalJobs = new InternalJobsService(this.$api.getInternalJobsApi());
    this.serviceOfficialJobs = new RomeJobsService(this.$api.getRomeJobsApi());
    
    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }
    // Récupération des paramètres
    if (this.$route.query) {
      if (this.$route.query.internal) {
        this.entityInternal = this.$route.query.internal == "true" ? true : false;
      }
    }
    
    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>